import { css } from '@emotion/css';

import mq from '../utils/mediaQuery';

export default css(
  mq({
    '& h1': {
      fontSize: [16, 24, 24],
      marginTop: ['8px', '16px', '16px'],
      marginBottom: ['16px', '24px', '24px'],
    },
    '& h2': {
      fontSize: [14, 20, 20],
      fontWeight: 500,
      marginTop: ['8px', '16px', '16px'],
      marginBottom: ['16px', '24px', '24px'],
    },
    '& h3': {
      fontSize: [12, 16, 16],
      fontWeight: 600,
      marginTop: ['16px', '24px', '24px'],
      marginBottom: ['16px', '24px', '24px'],
    },
    '& p': {
      fontSize: [12, 16, 16],
      lineHeight: ['20px', '30px', '30px'],
      marginTop: ['4px', '8px', '8px'],
      marginBottom: ['16px', '24px', '24px'],
      width: '100%',
    },
    '& ol': {
      fontSize: [12, 16, 16],
      paddingInlineStart: ['40px', '40px', '40px'],
    },
    '& a': {
      color: '#286f8c',
    },
    '& li': {
      marginBottom: ['8px', '12px', '12px'],
      fontSize: [12, 16, 16],
      lineHeight: ['20px', '30px', '30px'],
    },
    '& li p': {
      marginBottom: ['8px', '12px', '12px'],
    },
    '& ul': {
      marginTop: ['8px', '0px', '0px'],
      marginBottom: ['8px', '16px', '16px'],
    },
    '& li::marker': {
      fontWeight: 600,
    },
    '& img': {
      borderRadius: '8px',
    },
    '& .gif-container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '8px',
    },
    '& .gif-container img': {
      width: '100%',
      maxWidth: '650px',
    },
    '& .gift-image': {
      width: ['24px', '32px', '32px'],
      marginRight: ['12px', '20px', '20px'],
    },
    '& .gift-container': {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'start',
      flexDirection: 'row',
      marginBottom: ['24px', '32px', '32px'],
      fontSize: [10, 16, 16],
      lineHeight: ['20px', '30px', '30px'],
      width: '100%',
    },
  }),
);
